
import { createStore } from 'vuex'
import createdPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    userId:"",
    userEmail:""
  },
  getters:{
    getUserId(state){
      return state.userId
    },
    getCurrentEmail(state){
      return state.userEmail
    }
  },
  mutations: {
    addUserId(state, data){
      state.userId = data
    },
    addUserEmail(state, data){
      state.userEmail = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[createdPersistedState()]
})
